import Container from "../Container";
import RateCol from "../RateCol";
import Title from "../Title";
import Typo from "../Typo";
import { useRouter } from "next/router";
import localized from "../../locale/locales";

export default function SectionRates() {
  const { locale } = useRouter();
  const lang = localized[locale].homepage.rates;
  // const [openRate, setOpenRate] = useState(null);

  return (
    <div
      className="bg-black text-white pt-20 mb-80 md:mb-96 lg:mb-96"
      id="memberships"
    >
      <Container>
        <section className="pt-12 md:pt-24 pb-16">
          <header className="mb-2">
            <Title>{lang.title}</Title>
          </header>

          <Typo className="max-w-lg">{lang.body}</Typo>

          <div className="mt-14 -mb-72 flex flex-wrap justify-center items-start gap-12 md:gap-8">
            {lang.items.map((item, key) => {
              return (
                <RateCol
                  className="shrink-0 w-full md:w-[calc(50%-1rem)] lg:w-[calc(33.3333%-1.3333rem)]"
                  key={`PlanCol: ${key}`}
                  // isOpen={openRate === item.id}
                  // setOpen={() => openRate === item.id ? setOpenRate(null) : setOpenRate(item.id)}
                  {...item}
                />
              );
            })}
          </div>
        </section>
      </Container>
    </div>
  );
}
