import { useCallback, useEffect, useState } from "react";
import Container from "./Container";
import Title from "./Title";
import Typo from "./Typo";
import moment from 'moment';
import ScheduleTrialPanel from "./ScheduleTrialPanel";
import ScheduleTrialSlots from "./ScheduleTrialSlots";
import Lightbox from "./Lightbox";
import FormStepPersonal from "./form-steps/FormStepPersonal";
import FormStepSuccess from "./form-steps/FormStepSuccess";
import FormStepError from "./form-steps/FormStepError";
import { apiBase } from "../helpers/content";
import { sendTrialToGtm } from "../helpers/gtm";
import { useRouter } from "next/router";
import localized from "../locale/locales";
import { getCookie, removeCookie } from "../helpers/cookies";
import ScheduleTrialLocations from "./ScheduleTrialLocations";
import { getLocationStudioId } from "../helpers/locations";

const getDateRange = (count) => {
    const firstDate = moment().format('Y-MM-DD');

    return [...Array(count).keys()].map((_, key) => {
        return moment(firstDate).add(key, 'days');
    })
};

// todo: refactor this madness
export default function ScheduleTrial({ initialSlots = [], setHiddenNav, location = null, className = '' }) {
    const { locale } = useRouter();
    const lang = localized[locale];

    moment.locale(locale);

    const [daysToShow, setDaysToShow] = useState(1);
    const [dateRange, setDateRange] = useState(getDateRange(daysToShow));
    const [slots, setSlots] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedTime, setSelectedTime] = useState(null);
    const [result, setResult] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(location);
    const [loading, setLoading] = useState(false);

    const handleWindowResize = () => {
        setDaysToShow(window.innerWidth >= 768 ? 3 : 1);
    };

    const handleOpenPopup = (time) => {
        setSelectedTime(time);
        setShowPopup(true);
        setHiddenNav(true);
    }

    const handleClosePopup = useCallback(() => {
        setShowPopup(false);
        setHiddenNav(false);

        setTimeout(() => {
            setResult(null);
        }, 500);
    }, [setHiddenNav]);

    const handleSubmitPopup = useCallback(async (data) => {

        const body = {
            studioId: getLocationStudioId(selectedLocation),
            startDateTime: selectedTime,
            trainerRequired: true,
            leadCustomer: data,
            // note: JSON.stringify({testKey: 'test value', testKey2: 'test value 2' })
        };

        let hasUtmData = false;
        const utmData = getCookie('utmData');

        if (utmData) {
            body.note = utmData;
            hasUtmData = true;
        }

        await fetch('/api/book-trial', {
            method: 'POST',
            body: JSON.stringify(body)
        }).then((res) => {
            if (res.status > 200) {
                setResult('error');
            } else {
                sendTrialToGtm(data.email);
                setResult('success');

                if (hasUtmData) removeCookie('utmData');
            }

            return res.json();
        });
    }, [selectedTime, selectedLocation]);

    const handleClickPrev = () => {
        // Prevent from going further than today date
        if (dateRange[0].format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
            setDateRange(dateRange.map(item => item.add(daysToShow * -1, 'days')));
        }
    };

    const handleClickNext = () => {
        setDateRange(dateRange.map(item => item.add(daysToShow, 'days')));
    };

    // todo: optimize this
    useEffect(() => {
        async function fetchSlots() {
            if (selectedLocation === null) return;

            if (slots.length === 0) {
                setSlots(dateRange.length > 1 ? [initialSlots[1]] : initialSlots);
                return;
            }

            setLoading(true);

            let data = [];

            const queryFirst = new URLSearchParams({
                studioId: getLocationStudioId(selectedLocation),
                startDate: dateRange[0].format('YYYY-MM-DD'),
                endDate: dateRange[0].format('YYYY-MM-DD'),
            }).toString();

            try {
                const respFirst = await fetch(
                    `${apiBase}/connect/v1/trialsession?${queryFirst}`,
                    { method: 'GET' }
                );

                const dataFirst = await respFirst.json();
                data.push(dataFirst.slots);

            } catch (error) {
                console.error(error);
            }

            // ---

            if (dateRange.length > 1) {
                const querySecond = new URLSearchParams({
                    studioId: getLocationStudioId(selectedLocation),
                    startDate: dateRange[1].format('YYYY-MM-DD'),
                    endDate: dateRange[1].format('YYYY-MM-DD'),
                }).toString();

                try {
                    const respSecond = await fetch(
                        `${apiBase}/connect/v1/trialsession?${querySecond}`,
                        { method: 'GET' }
                    );

                    const dataSecond = await respSecond.json();

                    data.push(dataSecond.slots);
                } catch (error) {
                    console.error(error);
                }

                // ---

                const queryThird = new URLSearchParams({
                    studioId: getLocationStudioId(selectedLocation),
                    startDate: dateRange[2].format('YYYY-MM-DD'),
                    endDate: dateRange[2].format('YYYY-MM-DD'),
                }).toString();

                try {
                    const respThird = await fetch(
                        `${apiBase}/connect/v1/trialsession?${queryThird}`,
                        { method: 'GET' }
                    );

                    const dataThird = await respThird.json();

                    data.push(dataThird.slots);
                } catch (error) {
                    console.error(error);
                }

            }

            setSlots(data);
            setLoading(false);
        }

        fetchSlots();
    }, [dateRange, initialSlots, slots.length, selectedLocation]);

    useEffect(() => {
        window?.addEventListener('resize', handleWindowResize);
        handleWindowResize();

        return () => window?.removeEventListener('resize', handleWindowResize);
    }, []);

    useEffect(() => {
        setDateRange(getDateRange(daysToShow));
    }, [daysToShow, lang]);

    const [step, setStep] = useState(null);

    useEffect(() => {
        let newStep;

        if (!result) {
            newStep = (
                <FormStepPersonal
                    title={lang.homepage.trial.popup.title}
                    subtitle={lang.homepage.trial.popup.subtitle}
                    checkbox={true}
                    buttonLabel={lang.homepage.trial.popup.cta}
                    onSubmit={handleSubmitPopup}
                />
            );
        } else if (result === 'success') {
            newStep = (
                <FormStepSuccess
                    title={lang.homepage.trial.success.title}
                    body={lang.homepage.trial.success.body}
                    onClose={handleClosePopup}
                />
            );
        } else {
            newStep = (
                <FormStepError location={selectedLocation} onClose={handleClosePopup} />
            )
        }

        setStep(newStep);

    }, [result, handleClosePopup, handleSubmitPopup, lang, selectedLocation]);

    return (
        <>
            <Lightbox active={showPopup} onClose={handleClosePopup} size="medium">
                {step}
            </Lightbox>

            <section className={className} id="free-trial">
                <Container className="relative">
                    <header className="text-center flex flex-col items-center gap-4 md:gap-2">
                        <Title>{lang.homepage.trial.title}</Title>
                        <Typo className="max-w-lg mx-auto">{lang.homepage.trial.body}</Typo>
                        <Typo className="max-w-lg mx-auto mt-4">{lang.homepage.trial.chooseLocation}</Typo>
                    </header>

                    <ScheduleTrialLocations
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                    />
                </Container>

                {/* Fake container because of accordion overflow hidden */}
                <div className="w-full max-w-7xl mx-auto">
                    <div
                        className={`relative px-6 md:px-10 accordion accordion-0 ${selectedLocation !== null ? 'accordion-open' : ''}`}
                    >
                        <ScheduleTrialPanel dateRange={dateRange}
                            onPrev={handleClickPrev}
                            onNext={handleClickNext}
                        />

                        <ScheduleTrialSlots slots={slots}
                            handleOpenPopup={handleOpenPopup}
                            loading={loading}
                        />
                    </div>
                </div>

                <Container className="relative mt-10">
                    <p className="text-xs leading-6 text-center max-w-[70ch] mx-auto">{lang.homepage.trial.terms}</p>
                </Container>
            </section>
        </>
    )
}